@import _media-queries
@import _transitions

@import triangle

$label-width: 111px
$label-width-below-lg: 95px

section
	max-width: 1140px
	width: 100%
	margin: 15px auto
	display: flex
	border-radius: 10px
	box-sizing: border-box
	padding: 15px
	// background-color: #ffffff05

	@include transition(padding)
	
	@include respond-below(sm)
		display: block
		padding: 5px

	&:first-of-type
		margin-top: 30px

	.label, .content
		padding: 0
		margin: 15px 0px 0px 60px
		border-radius: 2px
		box-sizing: border-box
		// border: 1px solid #08242f4f
		// background-color: #062b4112
		font-weight: 100

		@include transition(margin, padding)

		@include respond-below(lg)
			margin-left: 30px

		@include respond-below(sm)
			padding-top: 0
			margin: 0

	.label
		width: $label-width
		padding-top: 20px
		line-height: 20px
		text-transform: uppercase
		font-size: 16px
		font-weight: 300
		text-align: right
		border-right: 1px solid #ffffff1a
		margin-left: 50px
		padding-right: 10px

		@include transition(margin, font-size, width)

		@include respond-below(lg)
			width: $label-width-below-lg
			margin-top: 0
			margin-left: 10px

		@include respond-below(sm)
			width: 100%
			text-align: center
			font-size: 20px
			margin: 10px auto 30px auto
			
		@include respond-below(ss)
			margin-top: 5px
			margin-bottom: 5px

	.content
		width: calc(100% - $label-width)
		position: relative

		@include transition(margin-top, padding, width)
		
		@include respond-below(lg)
			margin-top: 0

		@include respond-below(sm)
			width: 100%
			padding: 10px 25px

.section-row
	margin: 15px 0
	padding: 0
	font-size: 19px

	&:first-of-type
		margin-top: 15px
	margin-top: 40px

	@include transition(font-size)

	// &::before
	// 	content: " "
	// 	border-radius: 1px
	// 	margin-right: 10px
	// 	width: 5px
	// 	height: 5px
	// 	display: inline-block
	// 	background-color: #292828
	// 	vertical-align: middle

	@include respond-below(sm)
		font-size: 17px

.section-row-list
	list-style-type: none
	padding-left: 10px
	margin: 0 0 20px 0

	li:before
		content: " "
		border-radius: 1px
		margin-right: 10px
		width: 5px
		height: 5px
		display: inline-block
		background-color: #212121
		vertical-align: middle

.section-row-list-label
	// $label-color: #ffff00a1
	// color: black
	// $label-color: rgb(255 255 0 / 42%)

	// $label-color: rgb(255 255 0 / 8%)
	// color: #bf961d

	$label-color: rgb(255 255 255 / 8%)
	color: #ffffff8f

	position: relative
	padding: 3px 20px
	font-weight: 400
	background-color: $label-color
	margin: 15px 0

	&::before
		@include label-triagle(left, $label-color)

	&::after
		@include label-triagle(right, $label-color)

.section-row-item
	font-size: 17px
	margin: 15px 0
	padding: 0

	@include transition(font-size)
	
	@include respond-below(sm)
		font-size: 15px

	&:first-of-type
		margin-top: 5px

	&:last-of-type
		margin-bottom: 10px

@media print
	
	.section-row-list-label
		color: black