@mixin label-triagle($position, $background-color)
	position: absolute
	content: " "
	display: block
	width: 20px
	height: 100%
	top: 0

	@if $position == left
		left: -20px
		background-image: linear-gradient(to left top, $background-color 0%, $background-color 50%, transparent 50%)

	
	@else if $position == right
		right: -20px
		background-image: linear-gradient(to right bottom, $background-color 0%, $background-color 50%, transparent 50%)