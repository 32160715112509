@import _media-queries
@import _transitions

body
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	background-image: url(../public/polybackground_waifu2x_art_scale.webp)

.App
	// background-color: white
	// background-color: #2d2d2d

	// color: black
	color: #c1c1c1

	// position: absolute
	// left: 0
	// right: 0
	// top: 0
	// bottom: 0

	overflow: auto

	padding: 0 40px 0 350px

	@include transition(padding)

	@include respond-below(lg)
		padding-left: 280px

	@include respond-below(md)
		padding: 0 30px
	
	@include respond-below(sm)
		padding: 0 20px
	
	@include respond-below(xs)
		padding: 0 5px

	a
		color: #c1c1c1
		text-decoration: none
		border-bottom: 4px solid #ffffff0a
		padding: 2px

		.linkedin-icon
			filter: invert(40%)
			vertical-align: -4px
			margin-right: 5px
			width: 19px
			height: 19px

	aside
		position: fixed
		top: 64px
		left: 50%
		margin-left: -660px

		@include transition(top, left, margin)

		@include respond-below(lg)
			top: 0
			left: 0
			margin: 40px

		@include respond-below(md)
			position: relative
			overflow: hidden

		@include respond-below(ss)
			margin: 15px

		.profile
			display: block
			width: 200px
			height: 200px
			border-radius: 50%
			background-image: url(profile.jpg)
			background-repeat: no-repeat
			background-size: cover
			background-position: center center
			filter: grayscale(100%) contrast(235%) brightness(156%)
			border: 1px solid #9d8585
			opacity: .75
			z-index: 100
			margin-bottom: 40px
			
			@include transition(margin-bottom, width, height)
			
			@include respond-below(md)
				float: left
				margin-bottom: 0
			
			@include respond-below(sm)
				width: 150px
				height: 150px
				// float: none
				// margin: 0 auto 40px auto
			
			@include respond-below(ss)
				width: 140px
				height: 140px
			
			@include respond-below(us)
				float: none
				margin: 5px auto 40px auto

		.section-row-list
			padding: 0
			margin-top: 0

			@include transition(margin-top)

			@include respond-below(md)
				margin: 0
				margin-top: 25px
			
			@include respond-below(sm)
				margin-top: -15px
			
			@include respond-below(us)
				margin: 0 auto 0 auto

			li
				margin: 15px 0
				text-align: center

				@include transition(padding-left)

				@include respond-below(md)
					padding-left: 260px
					text-align: left
				
				@include respond-below(sm)
					padding-left: 200px
			
				@include respond-below(ss)
					padding-left: 170px
				
				@include respond-below(us)
					padding-left: 0
					text-align: center

				&:before
					display: none

	.label-date-range
		font-size: 16px
		margin-right: 5px
		margin-left: 10px

#my-name
	font-size: 24px
	font-weight: 500

::selection
	color: white
	background: gray

::-webkit-scrollbar
	width: 10px
	background-color: #121212

::-webkit-scrollbar-track
	// background-color: #f1f1f1

::-webkit-scrollbar-thumb
	background-color: #555

::-webkit-scrollbar-thumb:hover
	background-color: #888

@media print

	body
		background-color: white
		background-image: none

	.App
		color: black

		a
			color: black

		aside
			.profile
				border-radius: 0
				border: none

	.pagebreak
		page-break-before: always

	.exclude-from-print
		display: none


// Color palette /////////////////////////////////////////////

// #472D2D
// #553939
// #704F4F
// #A77979
// rgb(71, 45, 45)
// rgb(85, 57, 57)
// rgb(112, 79, 79)
// rgb(167, 121, 121)
//
// LINK: https://colorhunt.co/palette/472d2d553939704f4fa77979


// Background image /////////////////////////////////////////////
// LowPoly Generator
// Create lowpoly images free to use in personal and commercial projects.

// Buy Me a Coffee at ko-fi.com
// Dimensions
// Presets
// Select...
// Width
// 1920
// Height
// 1080
// Apply
// Image
// Select an Image...

// Use image
// Resize to image
// Geometry
// Variance: 30

// CellSize: 40

// Depth: 20

// Dither: 10

// Colours #1
// AddRemove
// hue: 218

// saturation: 0

// luminosity: 6

// Colours #2
// AddRemove
// hue: 349

// saturation: 0

// luminosity: 8

// Colours #3
// AddRemove
// hue: 18

// saturation: 0

// luminosity: 11

// Palettes
// Export
// Download PNG
// 139.8KB • 1920 ×1080

// by Charles Ojukwu
// GithubCodePen

// LINK: https://cojdev.github.io/lowpoly/